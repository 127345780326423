<template>
    <div class="view-root flex-column">
        <div style="display: flex;justify-content: space-between;align-items: center;">
            <p class="common-title">联系方式列表</p>
            <p class="btn-common" @click="editContact(null)" style="width: 120px;">新增联系方式</p>
        </div>

        <div class="table-margin-top flex-1-overflow-y">
            <div class="table-header table-margin-bottom">
                <div class="table-index">序号</div>
                <div class="table-title">联系方式名称</div>
                <div class="table-name">联系人名字</div>
                <div class="table-pos">联系人职位</div>
                <div class="table-phone">联系电话</div>
                <div class="table-email">联系邮箱</div>
                <div class="table-website">联网网址</div>
                <div class="table-wechat"> 联系微信</div>
                <div class="table-address">联系地址</div>
                <div class="table-operation">操作</div>
            </div>
            <div v-for="(contact, index) in dataList" :key="index" class="table-item table-margin-bottom">
                <div class="table-index">{{ (index + 1) }}</div>
                <div class="table-title" style="font-weight: bold">{{ contact.title }}</div>
                <div class="table-name" style="font-weight: bold">{{ contact.name }}</div>
                <div class="table-pos">{{ contact.position }}</div>
                <div class="table-phone">{{ contact.phone }}</div>
                <div class="table-email">{{ contact.email }}</div>
                <div class="table-website">{{ contact.website }}</div>
                <div class="table-wechat"> {{ contact.wechat }}</div>
                <div class="table-address" style="font-weight: bold">{{ contact.address }}</div>
                <div class="table-operation">
                    <div>
                        <p class="list-btn-blue" style="text-align: left;padding-left: 8px;"
                            @click="editContact(contact)">编辑</p>
                        <p class="list-btn-red" style="text-align: left;padding-left: 8px;margin-top: 10px;"
                            @click="doRemoveContact(contact)">删除</p>
                    </div>

                </div>
            </div>
        </div>
        <el-dialog :visible.sync="showContactInfoDialog" :title="contactInfo.id == '' ? '添加联系方式' : '编辑联系方式'"
            width="65%">
            <div>
                <el-form :model="contactInfo" ref="contactForm" :rules="rules">
                    <el-form-item label="联系方式名称" prop="title">
                        <el-input v-model="contactInfo.title" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人名字" prop="name">
                        <el-input v-model="contactInfo.name" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人职位" prop="position">
                        <el-input v-model="contactInfo.position" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" prop="phone">
                        <el-input v-model="contactInfo.phone" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="联系邮箱" prop="email">
                        <el-input v-model="contactInfo.email" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="联系地址" prop="address">
                        <el-input v-model="contactInfo.address" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="联网网址" prop="website">
                        <el-input v-model="contactInfo.website" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="联系微信" prop="wechat">
                        <el-input v-model="contactInfo.wechat" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-form>
                <div style="text-align: right;">
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="showContactInfoDialog = false">取 消</el-button>
                        <el-button type="primary" @click="saveContact()">确 定</el-button>
                    </span>
                </div>
            </div>
        </el-dialog>

    </div>
</template>
<script>
import '../../assets/common/common.css'
import {
    getContactInfos,
    updateContactInfo,
    removeContactInfo
} from '../../api/api'
export default ({
    name: 'index',
    data() {
        return {
            meeting_id: this.$route.query.meeting_id,
            dataList: [],
            contactInfo: {
                id: '',
                meeting_id: this.meeting_id,
                title: '',//联系方式名称
                name: '',//联系人名称
                position: '',//职位
                phone: '',//手机号码
                email: '',//邮箱
                website: '',//官网
                wechat: '',//微信
                address: ''//地址
            },
            rules: {
                // title: [
                //     { required: true, message: 'Please input', trigger: 'blur' }
                // ],
                // address: [
                //     { required: true, message: 'Please input', trigger: 'blur' }
                // ],
                // name: [
                //     { required: true, message: 'Please input', trigger: 'blur' }
                // ],
                // position: [
                //     { required: true, message: 'Please input', trigger: 'blur' }
                // ],
                // phone: [
                //     { required: true, message: 'Please input', trigger: 'blur' }
                // ],
                // email: [
                //     { required: true, message: 'Please input', trigger: 'blur' }
                // ]
            },
            showContactInfoDialog: false
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            getContactInfos(this.meeting_id).then((res) => {
                this.dataList = res.data.data
            })
        },
        doRemoveContact(row) {
            this.$alert('你确定要删除此联系方式吗？', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    if (action == 'confirm') {
                        removeContactInfo(row._id.$id).then((res) => {
                            this.$message.success('删除成功')
                            this.fetchData()
                        })
                    }
                }
            })
        },
        editContact(row) {
            if (row) {
                this.contactInfo = {
                    id: row._id.$id,
                    meeting_id: row.meeting_id,
                    title: row.title,
                    name: row.name,
                    position: row.position,
                    phone: row.phone,
                    email: row.email,
                    website: row.website,
                    wechat: row.wechat,
                    address: row.address
                }
            } else {
                this.contactInfo = {
                    id: '',
                    meeting_id: this.meeting_id,
                    title: '',
                    name: '',
                    position: '',
                    phone: '',
                    email: '',
                    website: '',
                    wechat: '',
                    address: ''
                }
            }
            this.showContactInfoDialog = true
        },
        saveContact() {
            if (this.contactInfo.title == '' ||
                this.contactInfo.name == '' ||
                this.contactInfo.position == '' ||
                this.contactInfo.email == '' ||
                this.contactInfo.wechat == '' ||
                this.contactInfo.address == '' ||
                this.contactInfo.phone == '') {
                this.$message.error('联系人名称、名字、职位、邮箱、手机号码、微信、地址必须要有一个');
                return
            }
            updateContactInfo(this.contactInfo).then((res) => {
                this.$message.success('保存成功')
                this.fetchData()
                this.showContactInfoDialog = false
            })
        }
    }
})
</script>
<style scoped lang="scss">
.table-index {
    flex: 1;
    text-align: center;
}

.table-title {
    flex: 2.5;
}

.table-name {
    flex: 2;
}

.table-pos {
    flex: 2;
}

.table-phone {
    flex: 2;
}

.table-email {
    flex: 3;
}

.table-website {
    flex: 3.5;
}

.table-wechat {
    flex: 2.0;
}

.table-address {
    flex: 3;
    padding: 0px 5px;
    box-sizing: border-box;
}

.table-operation {
    flex: 2;
}
</style>
